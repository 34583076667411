// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_container__padpO{
    padding: 1.5rem;
}

.footer_footer__EGObk p {margin: 0}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/footer.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA,yBAAW,SAAS","sourcesContent":[".container{\n    padding: 1.5rem;\n}\n\n.footer p {margin: 0}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `footer_container__padpO`,
	"footer": `footer_footer__EGObk`
};
export default ___CSS_LOADER_EXPORT___;
