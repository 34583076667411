import c from "./footer.module.css"

export default function Footer() {

    return(<div className={c.footer}>
    
        <div className={c.container}>
        <p>Copyright © 2024 MTClimatizaciones</p>
        </div>
        
    </div>)
}