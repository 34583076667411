import c from "./chooseus.module.css"
import logo1 from "./valija.svg";
import logo2 from "./money.svg";
import logo3 from "./shield.svg";
import logo4 from "./clients.svg";

export default function ChooseUs() {

    return(<div className={c.chooseUs}>
        
       <div className={c.container}>
          
         <div className={c.leftZone}>
            <div className={c.chooseTitle}>¿Por qué elegirnos?</div>

            <div>
                <h1>Descubre Más De Nuestro <span className={c.orange}>Profesionalismo</span></h1>
                <p><span className={c.italic}>MTClimatizaciones</span> te ofrece un equipo de expertos, capacitados para ayudarte con tus necesidades técnicas. Desde servicio de reparacion de Calderas hasta Aire acondicionado, te tenemos cubierto!</p>
            </div>

            <div className={c.mostrar}><a href="/trabajos" style={{all: "unset"}}>Mostrar</a></div>
         </div>

         <div className={c.rightZone}>
           <div className={c.itemsA}>
            <div className={c.item}>
                <div className={c.logo}>
                    <img src={logo1} className={c.logoImg} alt=""  loading="lazy"/>
                </div>

                <div className={c.itemText}>
                    <h2>Equipo Técnico Profesional</h2>
                    <p>Contamos con un equipo de técnicos con más de <span className={c.orangeItems}>15 años experiencia en el sector</span>. Conocedores de las Marcas a trabajar y con trabajos excepcionales que respaldan su trayectoria.</p>
                </div>
            </div>
            
            <div className={c.item}>
                <div className={c.logo}>
                    <img src={logo2} className={c.logoImg} alt="" loading="lazy"/>
                </div>

                <div className={c.itemText}>
                    <h2>Precios Accesibles</h2>
                    <p>Ofrecemos soluciones de alta calidad a precios <span className={c.orangeItems}>accesibles</span>, adaptándonos a las necesidades de nuestros clientes sin comprometer la <span className={c.orangeItems}>excelencia</span> del servicio.</p>
                </div>
            </div>

            </div>

            <div className={c.itemsB}>
            <div className={c.item}>
                <div className={c.logo}>
                    <img src={logo3} className={c.logoImg} alt=""  loading="lazy"/>
                </div>

                <div className={c.itemText}>
                    <h2>Servicio de Alta Calidad</h2>
                    <p>Nuestro equipo de profesionales usa materiales de <span className={c.orangeItems}>alta calidad</span> asegurando productos con una vida útil prolongada y soluciones confiables a <span className={c.orangeItems}>largo plazo</span>.</p>
                </div>
            </div>

            <div className={c.item}>
                <div className={c.logo}>
                    <img src={logo4} className={c.logoImg} alt="" loading="lazy" />
                </div>

                <div className={c.itemText}>
                    <h2 className={c.cliFelices}>Clientes Felices</h2>
                    <p>Nuestros clientes <span className={c.orangeItems}>nos recomiendan</span>, su satisfacción  es nuestra prioridad. Nos enorgullece contar con un historial de clientes felices que confían en nosotros </p>
                </div>
            </div>
            </div>
         </div>

       </div>

    </div>)
}